/** @jsx jsx */

import { Styled, jsx, Flex, Box, Grid } from "theme-ui";
import { graphql } from "gatsby";
import styled from "@emotion/styled";

import Layout from "../components/layout";
import Card from "../components/Card";
import Button from "../components/button";

import SEO from "../components/seo";

import BasicHero from "../components/BasicHero";

import ContactForm from "../components/ContactForm";

import QuestionIcon from "../images/icons/question-icon.svg";
import PackageIcon from "../images/icons/package-icon.svg";
import WarrantyIcon from "../images/icons/warranty-icon.svg";
import PrivacyIcon from "../images/icons/privacy-icon.svg";

import FacebookIcon from "../images/icons/facebook-black.svg";
import LinkedinIcon from "../images/icons/linkedin-black.svg";

import NextduraLogo from "../images/icons/nextdura-logo.svg";

import { Container } from "../components/item";

const StyledLink = styled.a`
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 3px;
  color: #000;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #1c75bc;
  }
`;

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "Raptect-contact-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    abstract: file(relativePath: { eq: "white-abstract.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    welding: file(relativePath: { eq: "man-welding.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    industrial: file(relativePath: { eq: "industrial-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const ContactPage = ({ data }) => (
  <Layout>
    <SEO title="Contact Raptect" />

    <BasicHero
      fluid={data.hero.childImageSharp.fluid}
      title={"building exterior"}
      heroTitle={"Contact"}
      shapeColor={"transparent"}
    />

    {/* <Box as="section" color={'white'} sx={{
      position: 'relative',
      backgroundColor: 'blue',
    }}>
      <Container py={13}>
        <Grid columns={[1, 2, 4]} gap={[8, 0]} sx={{justifyItems: 'center'}}>
          <Card
            textColor='white'
            titleColor='white'
            Icon={QuestionIcon}
            small
            compact
            title="shipping & returns" 
          >
            <Button text="Learn More" variant={'whiteOutline'} />
          </Card>
          <Card
            textColor='white'
            titleColor='white'
            Icon={PackageIcon}
            small
            compact
            title="product FAQs" 
          >
            <Button text="Learn More" variant={'whiteOutline'} />
          </Card>
          <Card
            textColor='white'
            titleColor='white'
            Icon={WarrantyIcon}
            small
            compact
            title="warranty" 
          >
            <Button text="Learn More" variant={'whiteOutline'} />
          </Card>
          <Card
            textColor='white'
            titleColor='white'
            Icon={PrivacyIcon}
            small
            compact
            title="privacy inquiry" 
          >
            <Button text="Learn More" variant={'whiteOutline'} />
          </Card>
        </Grid>
      </Container>
    </Box> */}

    <Box ax="section">
      <Container py={13}>
        <Flex sx={{ flexDirection: ["column", "row"] }}>
          <Flex sx={{ flex: "1 1 20%", flexDirection: "column" }}>
            <Styled.h3
              sx={{ textTransform: "uppercase", margin: 0, marginBottom: 2 }}
            >
              Contact Us
            </Styled.h3>
            <StyledLink href="tel:+18442284227">1-844-228-4227</StyledLink>
            <StyledLink href="mailto:Contact@raptect.com">
              contact@raptect.com
            </StyledLink>
            <StyledLink>
              15207 N 75th St Suite #101 <br /> Scottsdale, AZ 85260
            </StyledLink>
            <Styled.h3
              sx={{
                textTransform: "uppercase",
                maxWidth: "300px",
                marginBottom: 2,
              }}
            >
              Need Someone Specific?
            </Styled.h3>
            <Styled.h4 sx={{ marginBottom: 1 }}>Help & Support</Styled.h4>
            <StyledLink href="mailto:Contact@raptect.com">
              Contact@raptect.com
            </StyledLink>
            <Styled.h4 sx={{ marginBottom: 1 }}>Sales</Styled.h4>
            <StyledLink href="mailto:Contact@raptect.com">
              Contact@raptect.com
            </StyledLink>
            <Styled.h4 sx={{ marginBottom: 1 }}>Warranty</Styled.h4>
            <StyledLink href="mailto:Contact@raptect.com">
              Contact@raptect.com
            </StyledLink>
            <Styled.h3 sx={{ textTransform: "uppercase", marginBottom: 6 }}>
              Connect with us
            </Styled.h3>
            <Flex>
              <FacebookIcon sx={{ marginRight: 4 }} />
              <LinkedinIcon />
            </Flex>
          </Flex>
          <Box sx={{ flex: "1 2 80%", maxWidth: ["unset", "610px"] }}>
            <ContactForm name="General Contact" />
          </Box>
        </Flex>
      </Container>
    </Box>

    {/* <Box as="section" bg='green' color='white'>  
      <Container py={[8,13]} sx={{textAlign: 'center'}}>
        <Styled.h3 sx={{textTransform: 'uppercase'}}>Need wholesale options?</Styled.h3>
        <NextduraLogo sx={{maxWidth: '500px'}} />
      </Container>
    </Box>  */}
  </Layout>
);

export default ContactPage;
